import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "title_text" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "introduce" }
const _hoisted_5 = { class: "userTalk_out" }
const _hoisted_6 = { class: "userTalk" }
const _hoisted_7 = { class: "card_top" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "card_bottom" }
const _hoisted_10 = { class: "userText" }
const _hoisted_11 = { class: "title" }
const _hoisted_12 = { class: "desc" }
const _hoisted_13 = { class: "download_out" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { style: {"font-weight":"600"} }

import { lang } from '@/lang';
import playsUrl from '@/assets/img/newHome/plays.png';
import updownBlack from '@/assets/img/newHome/updownBlack.png';
import { computed } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'UserFeedback',
  setup(__props) {

const DataList = computed(() => [
  {
    img: require('@/assets/img/newHome/UserImg1.png'),
    name: 'A',
    career: lang.v.Career1,
    content: lang.v.UserTalk1,
  },
  {
    img: require('@/assets/img/newHome/UserImg2.png'),
    name: 'B',
    career: lang.v.Career2,
    content: lang.v.UserTalk2,
  },
  {
    img: require('@/assets/img/newHome/UserImg3.png'),
    name: 'C',
    career: lang.v.Career3,
    content: lang.v.UserTalk3,
  },
  {
    img: require('@/assets/img/newHome/UserImg4.png'),
    name: 'D',
    career: lang.v.Career4,
    content: lang.v.UserTalk4,
  },
]);
const userAgent = navigator.userAgent;
// 下载
const onDownload = () => {
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    // 是iOS设备
    window.open(window.download.ios, '_blank');
  } else if (/android/i.test(userAgent)) {
    // 是Android设备
    window.open(window.download.android, '_blank');
  } else {
    window.open(window.download.android, '_blank');
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_unref(lang).v.UserFeedback), 1),
      _createElementVNode("img", {
        src: _unref(playsUrl),
        alt: "",
        class: "title_img"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(lang).v.UserFeadbackDesc), 1),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(DataList.value, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "card",
            key: index
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("img", {
                src: item.img,
                alt: ""
              }, null, 8, _hoisted_8),
              _createElementVNode("div", null, [
                _createElementVNode("p", null, _toDisplayString(item.name), 1),
                _createElementVNode("p", null, _toDisplayString(item.career), 1)
              ])
            ]),
            _createElementVNode("span", _hoisted_9, _toDisplayString(item.content), 1)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("h2", _hoisted_11, _toDisplayString(_unref(lang).v.Platfrom), 1),
      _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(lang).v.PlatformDesc), 1)
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("button", {
        onClick: onDownload,
        class: "download"
      }, [
        _createElementVNode("img", {
          src: _unref(updownBlack),
          alt: ""
        }, null, 8, _hoisted_14),
        _createElementVNode("p", _hoisted_15, _toDisplayString(_unref(lang).v.DownloadNow), 1)
      ])
    ])
  ], 64))
}
}

})