import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main" }

import PopularizeTop from '@/components/NewTop/index.vue';
import DownLoad from './components/DownLoad.vue';
import MarketBackground from './components/MarketBackground.vue';
import ProductFeatures from './components/ProductFeatures.vue';
import UserFeedback from './components/UserFeedback.vue';
import CompetitiveAdvantages from './components/CompetitiveAdvantages.vue';
import BottomBack from './components/BottomBack.vue';
import ScrollReveal from 'scrollreveal';
import { onMounted } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

onMounted(() => {
  const sr = ScrollReveal({
    distance: '50px',
    duration: 1000,
    easing: 'ease-in-out',
    interval: 200,
    reset: true, // 每次进入视口时都触发
  });

  // 配置动画
  sr.reveal('.download_section', { origin: 'top' });
  sr.reveal('.market_background', { origin: 'right' });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(PopularizeTop),
    _createVNode(DownLoad, { class: "download_section" }),
    _createVNode(MarketBackground, { class: "market_background" }),
    _createVNode(ProductFeatures, { class: "product_features" }),
    _createVNode(CompetitiveAdvantages, { class: "competitive_advantages" }),
    _createVNode(UserFeedback, { class: "user_feedback" }),
    _createVNode(BottomBack)
  ]))
}
}

})